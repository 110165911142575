


























































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  PullRefresh,
  Popover,
  Icon,
  Popup,
  Sticky,
} from "vant";
import { ThisEnumerationCodes } from "@/shims-video";
import MyCalendar from "@/views/Classify/components/Calender/MyCalendar.vue";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [Popover.name]: Popover,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    MyCalendar,
  },
})
export default class CategorySelector extends Mixins(Mixin) {
  @Prop({ type: Boolean, default: false })
  hideCoupon!: boolean;
  @Prop({ type: Boolean, default: false })
  hidePrice!: boolean;

  showCategorySelector = false;

  public refreshShow = false;
  public showPopover = false;
  public data = new Date().getTime() + "";
  public str = this.data.slice(0, 10);
  public actions: ThisEnumerationCodes[] = [];
  public choose = "";
  public kindlist: dictionary.Codebook[] = [];
  public discountList: dictionary.Codebook[] = [];
  public priceList = [
    {
      code: "",
      name: "全部",
    },
    {
      code: "MOBILE_LESS_THAN_THREE_HUNDRED",
      name: "300元以下",
    },
    {
      code: "MOBILE_GREATER_THAN_THREE_HUNDRED",
      name: "300以上",
    },
  ];
  public timeList = [
    { id: "", name: "全部" },
    { id: "week", name: "一周内" },
    { id: "month", name: "一月内" },
    { id: "weekend", name: "本周末" },
    { id: "appoint", name: "指定时间" },
  ];
  public startTimeStamp? = 0;
  public endTimeStamp? = 0;
  public minPrice? = 0;
  public maxPrice? = 0;
  public selectK: string | undefined = "";
  public selectD: string | undefined = "";
  public selectP? = "";
  public selectT = "";
  public timeValue = "";
  public productList: good.RecommendProductDto[] | undefined = [];
  public tourList: good.RecommendProductDto[] | undefined = [];
  public today = "";

  get isWeekend(): boolean {
    return new Date().getDay() == 0 || new Date().getDay() == 6 ? true : false;
  }

  created(): void {
    this.recieveCodeBook();
  }

  open(): void {
    this.showCategorySelector = true;
  }

  //获取分类文案
  recieveCodeBook(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "performance.codeBook",
      apiMethod: this.$api.dictionaryApi.performance.codeBook,
      callback: ({ data }) => {
        this.kindlist = data.PlatformRulesEnum;
        this.discountList = data.DiscountRulesEnum;
        this.reset();
      },
    });
  }
  onloadRefresh(): void {
    setTimeout(() => {
      this.refreshShow = false;
    }, 2000);
  }

  // 关闭对话框
  onclosePop(): void {
    this.showCategorySelector = false;
  }

  //重置对话框
  reset(): void {
    this.selectK = this.kindlist[0].code!;
    this.selectD = this.discountList[0].code!;
    this.selectP = "";
    this.selectT = "";
    this.timeValue = "";
    delete this.startTimeStamp;
    delete this.endTimeStamp;
    this.deleteVariates();
  }
  //不传递某些参数
  deleteVariates(): void {
    delete this.startTimeStamp;
    delete this.endTimeStamp;
  }
  //对话框-挑选店铺
  oncheck(code: string | undefined): void {
    this.selectK = code;
  }
  //对话框-挑选折扣
  oncheckDiscount(code: string | undefined): void {
    this.selectD = code;
  }
  //对话框-挑选价格
  oncheckPrice(itm: { code: string }, code: string): void {
    this.selectP = code;
  }
  //对话框-挑选时间
  oncheckTime(itm: { id: string }, id: string): void {
    this.selectT = id;
    if (this.selectT == "week") {
      this.startTimeStamp = new Date().getTime();
      this.endTimeStamp = new Date().getTime() + 3600 * 1000 * 24 * 7;
    } else if (this.selectT == "month") {
      this.startTimeStamp = new Date().getTime();
      this.endTimeStamp = new Date().getTime() + 3600 * 1000 * 24 * 30;
    } else if (this.selectT == "weekend") {
      if (this.isWeekend == true) {
        this.startTimeStamp = new Date().getTime();
        this.endTimeStamp =
          new Date().setHours(23, 59, 59, 999) +
          (7 - new Date().getDay()) * 3600 * 1000 * 24;
      } else {
        // 设置时间为今日0点，获取时间戳
        this.startTimeStamp =
          new Date().setHours(0, 0, 0, 0) +
          (6 - new Date().getDay()) * 3600 * 1000 * 24;
        this.endTimeStamp =
          new Date().setHours(23, 59, 59, 999) +
          (7 - new Date().getDay()) * 3600 * 1000 * 24;
      }
    } else if (this.selectT == "appoint") {
      this.$nextTick(() => {
        let myCalendar = this.$refs["my-calendars"] as MyCalendar;
        if (myCalendar) {
          myCalendar.initData();
        }
      });
    } else if (this.selectT == "") {
      delete this.startTimeStamp;
      delete this.endTimeStamp;
    }
  }
  //选中某天
  onclick(date: string): void {
    this.timeValue = date;
    this.startTimeStamp = new Date(this.timeValue).getTime();
    this.endTimeStamp =
      new Date(this.timeValue).getTime() + (60 * 60 * 24 - 1) * 1000;
  }
  //左右点击切换月份
  onchange(): void {
    return;
  }
  //提交对话框筛选条件
  submit(): void {
    let form = {
      discountType: this.selectD,
      priceEnum: this.selectP,
      showType: this.selectK,
      startTimeStamp: 0,
      endTimeStamp: 0,
      type: "",
    };
    if (this.selectT === "appoint" && !this.timeValue) {
      form.startTimeStamp = new Date(new Date().toLocaleDateString()).getTime();
      form.endTimeStamp =
        new Date(new Date().toLocaleDateString()).getTime() +
        (60 * 60 * 24 - 1) * 1000;
      form.type = this.selectT;
      this.$emit("submit-select", form);
    } else {
      form.startTimeStamp = this.startTimeStamp!;
      form.endTimeStamp = this.endTimeStamp!;
      form.type = this.selectT;
      this.$emit("submit-select", form);
    }
    // this.$emit("submit-select", form);
    this.onclosePop();
  }
}
