


















import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Calendar } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import dayjs from "dayjs";
import { getDateByString } from "@/utils/index";

declare type CalendarDate = {
  date: Date;
  type: string;
  topInfo: string;
  text: number;
  bottomInfo: string;
};

@Component({
  components: { [Calendar.name]: Calendar },
})
export default class MyCalendar extends Mixins(Mixin) {
  public currentYear = "";
  public currentMonth = "";
  public holidayArr: Array<string> = [];
  public weekdayArr: Array<string> = [];
  public workdayArr: Array<string> = [];
  public maxDate = new Date(); // 需要先初始化一下，否则组件会认为没有这个属性
  public defaultDate: Date = new Date(); //默认选中日期
  public minDate: Date = new Date();
  public exitCommon: Date = new Date(); // 是否存在当前选中的日期存在多个场次的情况

  initData(): void {
    this.getHolidays(true);
    this.getWeekends(true);
    this.getMaxDate();
  }
  getDate(timeStamp: number): Date {
    return new Date(dayjs(timeStamp).format("YYYY/MM/DD"));
  }
  // 获取最大日期
  getMaxDate(): void {
    this.maxDate = this.getDate(
      new Date().getTime() + 24 * 60 * 60 * 1000 * 731
    );
  }
  readerData(day: CalendarDate): CalendarDate {
    let dd = this.dateFormat(new Date(day.date), "YYYY-MM-DD");
    let myDate = this.dateFormat(new Date(), "YYYY-MM-DD");
    if (this.holidayArr.indexOf(dd) > -1) {
      day.topInfo = "休";
    }
    if (dd === myDate) {
      day.bottomInfo = "今天";
    }
    return day;
  }
  getHolidays(first?: boolean): void {
    let year = "";
    let month = "";
    if (first) {
      year = String(new Date().getFullYear());
      month = String(new Date().getMonth() + 1);
    } else {
      year = this.currentYear;
      month = this.currentMonth;
    }
    this.InterfaceCacheModule.getData({
      cacheKey: "holidays.findHolidaysByYearOrMoon",
      apiMethod: this.$api.dictionaryApi.holidays.findHolidaysByYearOrMoon,
      params: [year, { month: month }],
      callback: ({ data }: { data: Array<dictionary.Holidays> }) => {
        data.forEach((item: dictionary.Holidays) => {
          if (this.holidayArr.indexOf(item.date!) === -1) {
            if (item.holiday) {
              this.holidayArr.push(item.date!);
            } else {
              this.workdayArr.push(item.date!);
            }
          }
        });
        this.getWeekends(false);
      },
    });
  }
  choseDay(day: any, type: string): void {
    this.$emit("choose", day);
  }
  // 切换月份
  changeMonth(day: any): void {
    let date = day.title;
    let arr = date.split("年");
    this.currentYear = arr[0];
    let arr2 = arr[1].split("月");
    let mm = "";
    if (Number(arr2[0]) < 10) {
      mm = "0" + arr2[0];
    } else {
      mm = arr2[0];
    }
    this.currentMonth = mm;
    this.getHolidays(false);
  }
  // 获取当前月份所有周末
  getWeekends(first: boolean): void {
    let year = "";
    let month = "";
    if (first) {
      year = String(new Date().getFullYear());
      month = String(new Date().getMonth() + 1);
    } else {
      year = this.currentYear;
      month = this.currentMonth;
    }
    let tempTime = new Date(Number(year), Number(month), 0);
    let time = new Date();
    for (let i = 1; i <= tempTime.getDate(); i++) {
      time.setFullYear(Number(year), Number(month) - 1, i);
      let t = "";
      if (i < 10) {
        t = "0" + i;
      } else {
        t = String(i);
      }
      let mm = "";
      if (Number(month) < 10) {
        mm = "0" + Number(month);
      } else {
        mm = month;
      }
      let day = time.getDay();
      if (day == 0 || day == 6) {
        this.weekdayArr.push(Number(year) + "-" + mm + "-" + t);
      }
    }
    this.getCurrentMonthWeekday();
  }
  // 判断当前月份哪几天为休息日
  getCurrentMonthWeekday(): void {
    // 循环遍历本月中所有周末日期和weekdayArr进行比对 如果两个日期相同 说明这一天为工作日
    for (let i = this.weekdayArr.length; i--; ) {
      for (let j = this.workdayArr.length; j--; ) {
        if (this.weekdayArr[i] === this.workdayArr[j]) {
          this.weekdayArr.splice(i, 1);
        }
      }
      for (let k = this.holidayArr.length; k--; ) {
        if (this.weekdayArr[i] === this.holidayArr[k]) {
          this.weekdayArr.splice(i, 1);
        }
      }
    }
  }
}
